import { Workbox } from 'workbox-window'

import { version } from './version.json'

const buildCacheName = (name: string) => `${name}_${version}`

export enum SWEventType {
  CLEAR_CACHE = 'CLEAR_CACHE',
  CLEAR_CACHE_ROUTES = 'CLEAR_CACHE_ROUTES',
}

export const cacheName = {
  assets: buildCacheName('assets'),
  data: buildCacheName('data'),
  page: buildCacheName('page'),
}

class WorkboxWindow {
  private static instance: WorkboxWindow | null = null
  private wb: Workbox | undefined

  private constructor() {
    try {
      this.wb = new Workbox('/entry.worker.js')
    } catch (err) {}
  }

  public register() {
    if (!('serviceWorker' in navigator) || !this?.wb) {
      console.log('Service workers are not supported.')
      return
    }

    this.wb
      .register()
      .then(() => {
        return navigator.serviceWorker.ready
      })
      .then(() => {
        if (navigator.serviceWorker.controller) {
          navigator.serviceWorker.controller.postMessage({
            type: 'SYNC_REMIX_MANIFEST',
            manifest: window.__remixManifest,
          })
        } else {
          navigator.serviceWorker.addEventListener('controllerchange', () => {
            navigator.serviceWorker.controller?.postMessage({
              type: 'SYNC_REMIX_MANIFEST',
              manifest: window.__remixManifest,
            })
          })
        }
      })
      .catch((error) => {
        console.log('Service worker registration failed')
        console.log(error)
      })
  }

  public static getInstance(): WorkboxWindow {
    if (this.instance === null) {
      this.instance = new WorkboxWindow()
    }
    return this.instance
  }

  public async clearCache() {
    this.wb?.messageSW({ type: SWEventType.CLEAR_CACHE })
  }

  public async clearCacheRoutes(cacheName: string, related: RegExp[]) {
    this.wb?.messageSW({
      type: SWEventType.CLEAR_CACHE_ROUTES,
      cacheName,
      related,
    })
  }
}

export default WorkboxWindow
